<template>
  <div @click="goto">
    <svg-icon icon-class="bulb-on"  />
    <span class="pl-5" style="color: #007bff" >如何使用</span>
  </div>
</template>

<script setup>
const url = ref('https://www.lamtau.com/howtouse/');

function goto() {
  window.open(url.value)
}
</script>
